<template>
  <v-card>
    <v-card-title class="msaBlue white--text">
      <v-container class="pa-0">
        <v-row dense>
          <v-col> Companies </v-col>
          <v-col align="right">
            <AddCompanies
              :key="reload"
              @close="reload = !reload"
              @confirm="onConfirm"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="py-0">
            <SearchBar @search="onSearch" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-data-table
      dense
      mobile-breakpoint="1"
      :headers="headers"
      :loading="loading"
      :items="orgs"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item="{ item }">
        <tr>
          <td :class="`${!!item.status ? 'msaBlue' : 'red'}--text`">
            <span @click="goToCompanyDetail(item)" style="cursor: pointer">
              {{ item.name }}
            </span>
          </td>
          <td class="actions">
            <RemoveCompany :companyId="item.id" @removed="onRemoved" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import RemoveCompany from '@/components/CompanyGroups/RemoveCompany.vue';
import AddCompanies from '@/components/CompanyGroups/AddCompanies.vue';
export default {
  name: 'GroupCompanies',
  components: {
    SearchBar,
    RemoveCompany,
    AddCompanies,
  },
  data() {
    return {
      reload: false,
      search: '',
      loading: false,
      orgs: [],
      headers: [
        {
          text: 'Name',
          value: 'Name',
          align: 'start',
          class: 'white--text msaBlue',
          sortable: false,
        },
        {
          text: 'Actions',
          align: 'start',
          class: 'white--text msaBlue',
          width: '50px',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    onSearch(data) {
      this.search = data;
      this.getGroupCompanies();
    },
    onRemoved() {
      this.getGroupCompanies();
    },
    onConfirm() {
      this.search = '';
      this.getGroupCompanies();
    },
    getGroupCompanies() {
      this.loading = true;
      let params = {
        groupId: this.$store.getters.selectedGroup.id,
        search: this.search ?? '',
      };
      this.$axios
        .post('get-group-companies?format=json', params)
        .then((response) => {
          this.loading = false;
          this.orgs = response.data;
        })
        .catch((error) => {
          this.loading = false;
          return error;
        });
    },
    goToCompanyDetail(company) {
      this.$store.commit('updateSelectedCompany', {
        ...company,
        companyGroupId: this.$store.getters.selectedGroup.id,
      });
      this.$router.push({
        name: 'CompanyDetail',
        params: { companyId: company.id },
      });
    },
  },
  mounted() {
    this.getGroupCompanies();
  },
};
</script>
<style>
.account-type div {
  border-radius: 5px;
}
</style>
