<template>
  <v-card elevation="3">
    <v-card-title class="msaBlue white--text">
      General Information
    </v-card-title>
    <v-card-subtitle class="text-body-1 mt-4 pb-0 lightGrey--text">
      GENERAL
    </v-card-subtitle>
    <v-divider class="mx-4"></v-divider>
    <v-form ref="form">
      <v-card-text class="py-0">
        <v-container>
          <v-row>
            <v-col :align="$vuetify.breakpoint.smAndDown ? '' : 'right'">
              Name:
            </v-col>
            <v-col cols="12" md="9" class="py-0">
              <v-text-field
                class="py-0"
                dense
                required
                counter
                maxlength="1000"
                outlined
                v-model="groupInfo.name"
                :rules="rules.name"
                data-testid="group-name"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-subtitle class="text-body-1 pb-0 lightGrey--text">
        LICENSES
      </v-card-subtitle>
      <v-divider class="mx-4"></v-divider>
      <v-card-text class="py-0">
        <v-container class="pb-0">
          <v-row>
            <v-col :align="$vuetify.breakpoint.smAndDown ? '' : 'right'">
              Licenses:
            </v-col>
            <v-col cols="12" md="9" class="py-0">
              <v-text-field
                dense
                class="py-0"
                required
                outlined
                type="number"
                :rules="rules.number"
                v-model="groupInfo.licenses"
                data-testid="group-web-licenses"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col :align="$vuetify.breakpoint.smAndDown ? '' : 'right'">
              Paid Licenses:
            </v-col>
            <v-col cols="12" md="9" class="py-0">
              <v-text-field
                dense
                class="py-0"
                required
                outlined
                type="number"
                :rules="rules.number"
                v-model="groupInfo.paidLicenses"
                data-testid="group-mobile-licenses"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>
    <v-card-actions class="px-0">
      <v-container class="px-4">
        <v-row>
          <v-col align="left">
            <DeleteGroup :groupId="groupInfo.id" v-if="groupInfo.id" />
          </v-col>
          <v-col align="right">
            <v-btn
              class="msaBlue white--text"
              :disabled="disableSave"
              @click="save()"
              data-testid="save-group"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import DeleteGroup from '@/components/CompanyGroups/DeleteGroup.vue';
export default {
  name: 'GeneralInfo',
  components: {
    DeleteGroup,
  },
  data() {
    return {
      groupInfo: {
        name: '',
        licenses: 0,
        paidLicenses: 0,
      },
      original: {},
      rules: {
        name: [(v) => !!v.toString().trim() || 'This field is required.'],
        number: [(v) => /^(0|[1-9]\d*)$/.test(v) || 'Invalid value.'],
      },
    };
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.groupInfo) === JSON.stringify(this.original) ||
        this.groupInfo.name.trim().length == 0 ||
        !this.$refs.form.validate()
      );
    },
  },
  methods: {
    save() {
      let url = 'save-company-group?format=json';
      let params = {
        group: this.groupInfo,
        loaderText: 'Saving...',
      };
      this.$axios.post(url, params).then((response) => {
        this.$store.commit('updateSelectedGroup', {
          id: response.data.id,
          name: this.groupInfo.name,
        });
        this.original = JSON.parse(JSON.stringify(this.groupInfo));
      });
    },
    getGroup() {
      let url = 'get-company-group?format=json';
      let params = {
        id: this.$store.getters.selectedGroup.id,
        loaderText: 'Saving...',
      };
      this.$axios.post(url, params).then((response) => {
        this.original = JSON.parse(JSON.stringify(response.data));
        this.groupInfo = JSON.parse(JSON.stringify(response.data));
      });
    },
  },
  mounted() {
    if (this.$store.getters.selectedGroup.id != -1) {
      this.getGroup();
    } else {
      this.original = JSON.parse(JSON.stringify(this.groupInfo));
    }
  },
};
</script>
