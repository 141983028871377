<template>
  <v-container fluid>
    <v-row>
      <v-col align="right">
        <MoveUserDialog />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" cols="12">
        <GeneralInfo />
      </v-col>
      <v-col v-if="$store.getters.selectedGroup.id != -1">
        <GroupCompanies />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import GeneralInfo from '@/components/CompanyGroups/GeneralInfo.vue';
import GroupCompanies from '@/components/CompanyGroups/GroupCompanies.vue';
import MoveUserDialog from '@/components/CompanyGroups/MoveUserDialog.vue';
export default {
  name: 'GroupDetail',
  components: {
    GeneralInfo,
    GroupCompanies,
    MoveUserDialog,
  },
  mounted() {},
};
</script>
