<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>Remove Company</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Remove Company From Group
      </v-card-title>
      <v-card-text class="mt-2 text-body-1">
        Are you sure to remove company from this group?
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-col class="grow" align="right">
            <v-btn
              @click="dialog = false"
              plain
              class="msaBlue--text font-weight-bold"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col class="shrink" align="right">
            <v-btn @click="confirm()" plain class="msaBlue white--text">
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'RemoveCompany',
  props: {
    companyId: {
      type: Number,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    confirm() {
      let params = {
        companyId: this.companyId,
        groupId: this.$store.getters.selectedGroup.id,
        loaderText: 'Saving...',
      };

      let url = 'remove-company-from-group?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('removed');
          this.close();
        })
        .catch(error => error);
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
