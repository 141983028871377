<template>
  <v-container class="pa-0 mx-0" fluid>
    <v-dialog v-model="dialog" max-width="800px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="msaBlue white--text" v-bind="attrs" v-on="on" v-blur>
          move user
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline msaBlue white--text">
          <v-row>
            <v-col class="grow"> Move User </v-col>
            <v-col class="shrink">
              <v-btn icon dark @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row align="center">
            <v-col cols="3" align="right"> Select origin </v-col>
            <v-col cols="7">
              <CustomizedAutoComplete
                :value="originCompanyId"
                hide-details
                :items="companies"
                item-text="name"
                @change="originCompanyId = $event"
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" align="right"> Select user </v-col>
            <v-col cols="7">
              <CustomizedAutoComplete
                :value="selectedUserId"
                hide-details
                :items="users"
                item-text="fullName"
                :disabled="!originCompanyId"
                @change="selectedUserId = $event"
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" align="right"> Select destination </v-col>
            <v-col cols="7">
              <CustomizedAutoComplete
                :value="destinationCompanyId"
                hide-details
                :items="destinationCompanies"
                item-text="name"
                :disabled="!originCompanyId || !selectedUserId"
                @change="destinationCompanyId = $event"
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" align="right"> Temporary password </v-col>
            <v-col cols="7">
              <v-text-field
                v-model="password"
                dense
                outlined
                hide-details
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" align="right"> Options </v-col>
            <v-col cols="7">
              <v-checkbox
                v-model="moveTrainingRecords"
                class="mt-0"
                :true-value="1"
                :false-value="0"
                hide-details
                label="Move training records"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3"> </v-col>
            <v-col cols="7">
              <v-checkbox
                v-model="sendEmail"
                class="mt-0"
                :true-value="1"
                :false-value="0"
                hide-details
                label="Send credentials email"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="msaBlue white--text"
            :disabled="disableConfirm"
            @click="confirm()"
          >
            confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import CustomizedAutoComplete from '@/components/CustomizedAutoComplete.vue';
import { PasswordGenerator } from '@/plugins/PasswordGenerator.js';
export default {
  name: 'MoveUserDialog',
  components: {
    CustomizedAutoComplete,
  },
  data() {
    return {
      dialog: false,
      companies: [],
      originCompanyId: 0,
      selectedUserId: 0,
      users: [],
      destinationCompanyId: 0,
      moveTrainingRecords: 0,
      password: '',
      sendEmail: 0,
    };
  },
  computed: {
    destinationCompanies() {
      return this.companies.filter((c) => c.id != this.originCompanyId);
    },
    disableConfirm() {
      return (
        !this.originCompanyId ||
        !this.selectedUserId ||
        !this.destinationCompanyId
      );
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getCompanies();
        this.generatePassword();
      }
    },
    originCompanyId(val) {
      this.users = [];
      this.selectedUserId = 0;
      this.destinationCompanyId = 0;
      if (val) {
        this.getUsers();
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.users = [];
      this.selectedUserId = 0;
      this.destinationCompanyId = 0;
      this.companies = [];
      this.originCompanyId = 0;
      this.password = '';
      this.moveTrainingRecords = 0;
      this.sendEmail = 0;
    },
    getCompanies() {
      const params = {
        groupId: this.$store.getters.selectedGroup.id,
        search: '',
        loaderText: 'Loading...',
      };
      const url = 'get-group-companies?format=json';
      this.$axios.post(url, params).then((response) => {
        response.data.forEach((c) => {
          if (c.status == 1) {
            c.selected = true;
            this.companies.push(c);
          }
        });
      });
    },
    getUsers() {
      const options = {
        sortBy: ['fullName'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: 999999,
      };
      const params = {
        options,
        filters: this.$constants.EMPLOYEE_LIST_FILTERS,
        loaderText: 'Loading...',
        companyId: this.originCompanyId,
      };
      this.$axios
        .post('get-users-by-company?format=json', params)
        .then((response) => {
          this.users = response.data.items;
        });
    },
    confirm() {
      const params = {
        originCompanyId: this.originCompanyId,
        loaderText: 'Loading...',
        selectedUserId: this.selectedUserId,
        destinationCompanyId: this.destinationCompanyId,
        moveTrainingRecords: this.moveTrainingRecords,
        snackText: 'User moved successfully.',
        groupId: this.$store.getters.selectedGroup.id,
        password: this.password,
        sendEmail: this.sendEmail,
      };
      const url = 'move-user?format=json';
      this.$axios.post(url, params).then(() => {
        this.close();
      });
    },
    generatePassword() {
      this.password = PasswordGenerator.generate(
        6,
        this.$constants.PASSWORD_VALIDATION_REGEX,
      );
    },
  },
};
</script>
