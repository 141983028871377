<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-btn
        v-blur
        class="msaBlue--text"
        small
        depressed
        v-bind="attrs"
        v-on="{ ...dialog }"
        @click="
          getCompanies();
          getCompaniesCount();
        "
      >
        <v-icon>mdi-plus</v-icon>
        Add Companies
      </v-btn>
    </template>
    <v-card>
      <TableTitle
        @search="
          search = $event;
          loading = true;
          orgs = [];
          options.page = 1;
        "
        title="Add Companies"
      />
      <v-data-table
        dense
        mobile-breakpoint="1"
        hide-default-header
        :loading="loading"
        :items="orgs"
        :server-items-length="orgCount.length"
        :footer-props="{
          'items-per-page-options': [5, 10, 15],
        }"
        :options.sync="options"
        :items-per-page.sync="perPage"
        :page.sync="page"
      >
        <template v-slot:header>
          <v-container class="msaBlue white--text py-0">
            <v-row dense align="center">
              <v-col> Name </v-col>
              <v-col align="right"> Select All </v-col>
              <v-col class="shrink">
                <v-checkbox
                  style="height: 32px; width: 40px"
                  :key="reload"
                  dense
                  dark
                  color="white"
                  @change="selectAll($event)"
                  v-model="allSelected"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item="{ item }">
          <v-container class="pb-0">
            <v-row
              dense
              align="center"
              style="border-bottom: 1px #dcdcdc solid"
            >
              <v-col class="msaBlue--text text-body-2">
                {{ item.name }}
              </v-col>
              <v-col align="right">
                <v-checkbox
                  color="msaBlue"
                  style="height: 32px; width: 40px"
                  v-model="selectedCompanyIds"
                  :key="reload"
                  dense
                  :value="item.id"
                  @change="checkSelectAll()"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-row no-gutters>
          <v-col class="grow" align="right">
            <v-btn
              @click="close()"
              plain
              class="msaBlue--text font-weight-bold"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col class="shrink" align="right">
            <v-btn
              @click="confirm()"
              v-blur
              class="msaBlue white--text"
              :disabled="selectedCompanyIds.length == 0"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TableTitle from '@/components/TableTitle.vue';
export default {
  name: 'AddCompanies',
  components: {
    TableTitle,
  },
  watch: {
    options: {
      handler() {
        this.getCompanies();
        this.getCompaniesCount();
      },
    },
    search: function () {
      this.getCompanies();
      this.getCompaniesCount();
    },
  },
  data() {
    return {
      reload: false,
      selectedCompanyIds: [],
      dialog: false,
      search: '',
      loading: false,
      page: this.$constants.PAGINATION.DEFAULT_PAGE,
      perPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      orgs: [],
      orgCount: 0,
      options: {},
      allSelected: false,
    };
  },
  methods: {
    getCompanies() {
      this.loading = true;
      if (this.search == null) {
        this.search = '';
      }

      let params = {
        page: this.options.page ? this.options.page : 1,
        perPage: this.options.itemsPerPage ? this.options.itemsPerPage : 10,
        search: this.search,
        sortDesc: this.options.sortDesc ? this.options.sortDesc[0] : false,
        sortBy: this.options.sortBy ? this.options.sortBy[0] : 'name',
      };

      let url = 'get-available-companies?format=json';

      this.$axios
        .post(url, params)
        .then(response => {
          this.loading = false;
          this.orgs = response.data;
          this.reload = !this.reload;
          this.checkSelectAll();
        })
        .catch(error => {
          this.loading = false;
          return error;
        });
    },
    getCompaniesCount() {
      if (this.search == null) {
        this.search = '';
      }

      let params = {
        search: this.search,
      };

      let url = 'get-available-companies-count?format=json';

      this.$axios
        .post(url, params)
        .then(response => (this.orgCount = response.data))
        .catch(error => error);
    },
    selectAll(val) {
      this.allSelected = val;
      if (val) {
        this.selectedCompanyIds = this.orgCount;
      } else {
        this.selectedCompanyIds = [];
      }
    },
    checkSelectAll() {
      this.allSelected = this.orgs.every(item =>
        this.selectedCompanyIds.includes(item.id),
      );
    },
    confirm() {
      let params = {
        companyIds: this.selectedCompanyIds,
        groupId: this.$store.getters.selectedGroup.id,
        loaderText: 'Saving...',
      };

      let url = 'add-companies-to-group?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.close();
          this.$emit('confirm');
        })
        .catch(error => error);
    },
    close() {
      this.$emit('close');
      this.dialog = false;
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
