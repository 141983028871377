<!--
Import this table title component for any datatables in an v-card. Just add "@search=[YOUR SEARCH VARIABLE] = $event" on the TableTitle component tag. And have a watcher for [YOUR SEARCH VARIABLE] in the view that you are using this component. Example:

    <TableTitle @search="search = $event" title="Companies" />

    data() {
        return {
            search: "",
        }
    }

    watch: {
            search: function () {
                this.getOrganizations(); // Your endpoint must be able to take a search keyword as a parameter for this to work.
            },
        },  
	
Once this is done, when you start to type, after 0.5s, this component will return the search keyword, and the watcher on [YOUR SEARCH VARIABLE] will trigger the call for backend and gets you the result.
-->
<template>
  <v-card-title
    :class="`white--text msaBlue ${
      $vuetify.breakpoint.smAndDown ? 'px-1' : 'px-3'
    }`"
  >
    <v-row v-if="$vuetify.breakpoint.mdAndUp">
      <v-col align-self="center">{{ title }}</v-col>
      <v-col>
        <SearchBar
          class="search-bar"
          :darkTheme="true"
          @search="$emit('search', $event)"
        />
      </v-col>
    </v-row>
    <v-container fluid v-if="$vuetify.breakpoint.smAndDown">
      <v-row>
        <v-col class="py-0">
          {{ title }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <SearchBar
            class="search-bar"
            :darkTheme="true"
            @search="$emit('search', $event)"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card-title>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'TableTitle',
  components: {
    SearchBar,
  },
  props: {
    title: {
      type: String,
    },
  },
  watch: {
    search: function () {
      this.getOrganizations();
    },
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style></style>
