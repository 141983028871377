import { render, staticRenderFns } from "./DeleteGroup.vue?vue&type=template&id=516e4e0f&scoped=true"
import script from "./DeleteGroup.vue?vue&type=script&lang=js"
export * from "./DeleteGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516e4e0f",
  null
  
)

export default component.exports